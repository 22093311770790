<template>
  <div class="main-content bg-default">
    <!-- Navbar -->
    <base-nav
      class="navbar-top navbar-horizontal navbar-dark"
      containerClasses="px-4 container"
      expand
    >
      <router-link slot="brand" class="navbar-brand" to="/">
        <img
          src="img/brand/white.png"
          style="width: 70px !important; height: 70px !important"
        />
      </router-link>

      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="img/brand/green.png" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/">
              <i class="ni ni-planet"></i>
              <span class="nav-link-inner--text">Home</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="!LoginNavGuard">
            <router-link class="nav-link nav-link-icon" to="/register">
              <i class="ni ni-circle-08"></i>
              <span class="nav-link-inner--text">Register</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="!LoginNavGuard">
            <router-link class="nav-link nav-link-icon" to="/login">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="LoginNavGuard">
            <router-link class="nav-link nav-link-icon" to="/dashboard">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="LoginNavGuard">
            <a class="nav-link nav-link-icon">
              <i class="ni ni-user-run"></i>
              <span @click="onLogout" class="nav-link-inner--text">Logout</span>
            </a>
          </li>
        </ul>
      </template>
    </base-nav>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white">{{ Title }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <a href="https://dhcontest.com" class="font-weight-bold ml-1"
                >DH Contest</a
              >
            </div>
          </div>
          <div class="col-xl-6">
            <ul
              class="
                nav nav-footer
                justify-content-center justify-content-xl-end
              "
            >
              <li class="nav-item">
                <router-link to="/" class="nav-link">
                  <span>Home</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/about-us" class="nav-link">
                  <span>About Us</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/contact-us" class="nav-link">
                  <span>Contact Us</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
/* eslint-disable */
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      LoginNavGuard: false,
      Title: "Welcome to DH Contest!",
    };
  },
  created() {
    this.isLoginMenu();
  },
  methods: {
    isLoginMenu() {
      if (localStorage.getItem("userData")) {
        this.LoginNavGuard = true;
        return this.LoginNavGuard;
      } else {
        return this.LoginNavGuard;
      }
    },
    onLogout() {
      //console.log('user logout 1', 'Side bar')
      this.$store.dispatch("logout");
      //console.log('user logout 2', 'Side bar')
    },
  },
};
</script>
<style></style>
