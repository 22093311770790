import axios from "axios";
import { API_URL } from "../../common/config";
import router from "../../router";

export default {
    state: {
        user: null,
        token: null,
        is_admin: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },

        setAdmin: (state, payload) => {
            state.is_admin = payload;
        },
    },
    actions: {
        signUserUp({ commit }, payload) {
            var postData = {
                username: payload.username,
                email: payload.email,
                password: payload.password,
                first_name: payload.first_name,
                last_name: payload.last_name,
                phone_number: payload.phone_number,
            };
            ////console.log('== postData', postData)
            commit("setLoading", true);
            axios
                .post(API_URL + "register/", postData)
                .then((user) => {
                    if (user.data.message) {
                        alert(user.data.message);
                    } else {
                        commit("setLoading", false);
                        const newUser = {
                            token: user.data.token,
                            id: user.data.data.id,
                            username: user.data.data.username,
                            email: user.data.data.email,
                            is_admin: user.data.data.is_staff,
                        };
                        ////console.log('== user =', newUser.token)
                        localStorage.setItem("userData", JSON.stringify(newUser));
                        commit("setUser", newUser);
                        commit("setToken", newUser.token);
                        alert("Signup was Successful!");
                    }
                })
                .catch((err) => {
                    if (err) {
                        alert("Error! Network is Slow or Something went Wrong, please the Again!");
                    }
                });
        },
        signUserUpReferral({ commit }, payload) {
            var postData = {
                username: payload.username,
                email: payload.email,
                password: payload.password,
                referral_code: payload.referralCode,
                first_name: payload.first_name,
                last_name: payload.last_name,
                phone_number: payload.phone_number,
            };
            ////console.log('== postData', postData)
            commit("setLoading", true);
            axios
                .post(API_URL + "register-referral/", postData)
                .then((user) => {
                    if (user.data.message) {
                        alert(user.data.message);
                    } else {
                        commit("setLoading", false);
                        const newUser = {
                            token: user.data.token,
                            id: user.data.data.id,
                            username: user.data.data.username,
                            email: user.data.data.email,
                            is_admin: user.data.data.is_staff,
                        };
                        ////console.log('== user =', newUser.token)
                        localStorage.setItem("userData", JSON.stringify(newUser));
                        commit("setUser", newUser);
                        commit("setToken", newUser.token);
                        alert("Signup was Successful!");
                    }
                })
                .catch((err) => {
                    if (err) {
                        alert("Error! Network is Slow or Something went Wrong, please the Again!");
                    }
                });
        },
        signUserIn({ commit }, payload) {
            commit("setLoading", true);
            var postData = {
                username: payload.username,
                password: payload.password,
            };
            axios
                .post(API_URL + "authenticate/", postData)
                .then((user) => {
                    if (user.data.message) {
                        alert(user.data.message);
                    } else {
                        commit("setLoading", false);
                        const newUser = {
                            token: user.data.token,
                            id: user.data.user.id,
                            username: user.data.user.username,
                            email: user.data.user.email,
                            is_admin: user.data.user.is_staff,
                        };
                        commit("setUser", newUser);
                        commit("setToken", newUser.token);
                        if (!user.data.user.is_staff) {
                            commit("setToken", newUser.token);
                            commit("setUser", newUser);
                            commit("setAdmin", newUser.is_admin);
                            localStorage.setItem("userData", JSON.stringify(newUser));
                            localStorage.setItem("tokenData", JSON.stringify(newUser.token));
                            localStorage.setItem(
                                "isAdminData",
                                JSON.stringify(newUser.is_admin)
                            );
                            localStorage.setItem("username", JSON.stringify(newUser.username));
                            //console.log('== token =', user.data.user.is_staff)
                            //console.log('getItem', JSON.parse(localStorage.getItem('userData')))
                        } else {
                            commit("setUser", null);
                            commit("setAdmin", null);
                        }
                    }
                    ////console.log('== token =', this.getters.token)
                })
                .catch((err) => {
                    if (err) {
                        alert("Error! Network is Slow or Something went Wrong, please the Again!");
                    }
                });
        },

        autoSignIn({ commit }, payload) {
            payload = JSON.parse(localStorage.getItem("userData"));
            ////console.log('autoSignIn payload', payload)
            commit("setUser", {
                token: payload.token,
                id: payload.id,
                username: payload.username,
                email: payload.email,
                is_admin: payload.is_staff,
            });
            if (payload.is_staff == false) {
                ////console.log('autoSignIn to User')
                router.push("user");
            } else if (payload.is_staff == true) {
                ////console.log('autoSignIn to Admin')
                router.push("admin");
            }
        },
        logout({ commit }) {
            localStorage.clear();
            commit("setUser", null);
            //location.href = 'https://vestrade.wiseclub2k.com'
            window.location.reload("/");
        },
        studentSignUp({ commit }, payload) {
            var postData = {
                username: payload.username,
                email: payload.email,
                password: payload.password,
                first_name: payload.first_name,
                last_name: payload.last_name,
                phone_number: payload.phone_number,
                whatsapp_number: payload.whatsapp_number,
            };
            ////console.log('== postData', postData)
            commit("setLoading", true);
            axios
                .post(API_URL + "student-register/", postData)
                .then(() => {
                    commit("setLoading", false);
                    alert("Signup was Successful!");
                    router.push("/student-success");
                })
                .catch((err) => {
                    if (err) {
                        alert("Error! Username Already Exists!");
                    }
                });
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        is_admin(state) {
            return state.is_admin;
        },
    },
};