import axios from "axios";
import API_URL from "../../common/config";
import user from "./../user";

export default {
  state: {
    walletBalance: "",
  },
  mutations: {
    setUpWalletBalance(state, payload) {
      state.walletBalance = payload;
    },
  },
  actions: {
    // Depost
    async loadDepost() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      const result = await axios.get(
        API_URL + "deposit-withdraw/deposit-details/",
        { headers: headers }
      );
      return result.data;
    },
    //
    loadWithdrawal({ commit }, payload) {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };
      var postData = {
        amount: payload.requestAmount,
        bank_details: payload.bankDetails,
      };
      axios
        .post(API_URL + "deposit-withdraw/withdrawal/", postData, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.res) {
            //console.log('res.data.res', res.data.res)
            commit("setSuccess", true);
            commit("setError", false);
            alert(
              "Your Withdrawal Requets was Success, We will get back to you Shortly!"
            );
          } else {
            commit("setError", true);
            commit("setSuccess", false);
            alert("You have Pending Withdrawal!");
          }
        });
    },

    // Personal Profile
    loadPersonalProfile({ commit }, payload) {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };
      axios
        .post(API_URL + "profile/user-details/", payload, { headers: headers })
        .then((res) => {
          //console.log('== res ==', res)
          if (res.data) {
            //console.log('Profile data ==', res.data)
            commit("setSuccess", true);
            alert("Profile submit was successful!");
          } else {
            commit("setError", true);
            alert("Error submitting Profile!");
          }
        });
    },

    // Uploaded Document
    async loadWalletBalance({ commit }) {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      const result = await axios.get(API_URL + "wallet/balance/", {
        headers: headers,
      });
      const data = result.data[0].balance;
      //console.log('wallet balance ==',  data)
      commit("setUpWalletBalance", data);
      return data;
    },
    //
    loadChangePassword({ commit }, payload) {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };
      //console.log('= payload== ', payload)
      axios
        .post(API_URL + "change-password/", payload, { headers: headers })
        .then((res) => {
          //console.log('== res ==', res)
          if (res.data.status == "success") {
            alert("Password updated successfully!");
            commit("setSuccess", true);
            //this.$store.dispatch('logout')
            user.actions.logout({ commit });
          } else {
            commit("setError", true);
            alert("Error, Wrong password!");
          }
        })
        .catch((err) => {
          if (err) {
            commit("setError", true);
            alert("Error, Wrong old password!");
          }
        });
    },
    //
    async loadReferralBonus() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      const result = await axios.get(API_URL + "referral/referral-bonus/", {
        headers: headers,
      });
      return result.data;
    },
    //
    async loadWatchVideoReward() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      const result = await axios.get(
        API_URL + "live-video/video-watched-reward/",
        { headers: headers }
      );
      return result.data;
    },
    //
    async loadWatchVideoCount() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      const result = await axios.get(
        API_URL + "live-video/video-watch-count/",
        { headers: headers }
      );
      return result.data;
    },
  },
  getters: {
    getWalletBalance(state) {
      return state.walletBalance;
    },
  },
};
