var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-world-2 text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Reality TV shows',
          icon: 'ni ni-tv-2 text-blue',
          path: '/reality-tv-shows',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Live Shows Vote',
          icon: 'ni ni-check-bold text-dark-blue',
          path: '/live-show-vote-list',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Show Results',
          icon: 'ni ni-trophy text-blue',
          path: '/live-show-results',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Schedule Shows',
          icon: 'ni ni-camera-compact text-orange',
          path: '/upcoming-shows',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Online House',
          icon: 'ni ni-shop text-green',
          path: '/online-house',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Live Stream',
          icon: 'ni ni-cloud-download-95 text-blue',
          path: '/live-show-vote-list',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Replay Video',
          icon: 'ni ni-album-2 text-blue',
          path: '/replay-video',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Top-up your Wallet',
          icon: 'ni ni-credit-card text-dark-grean',
          path: '/wallet',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Coins Bonus Wallet',
          icon: 'ni ni-satisfied text-orange',
          path: '/coins-wallet',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Referral Bonus',
          icon: 'ni ni-bullet-list-67 text-red',
          path: '/referral-bonus',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Referral Bonus Withdrawal',
          icon: 'ni ni-bullet-list-67 text-red',
          path: '/referral-bonus-withdrawal',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'User Profile',
          icon: 'ni ni-single-02 text-yellow',
          path: '/profile',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Add Bank Details',
          icon: 'ni ni-credit-card text-blue',
          path: '/add-bank-details',
        }}}),_c('div',{attrs:{"id":"logout-div"}},[_c('a',{attrs:{"background-color":_vm.sidebarBackground}},[_c('i',{staticClass:"ni ni-user-run text-red",attrs:{"id":"logout-i"}}),_c('span',{staticClass:"sidebar-item",on:{"click":_vm.onLogout}},[_vm._v("Logout")])])])],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }