import http from "../common/http-common";


class UploadFilesService {




    upload(formData, onUploadProgress) {

        return http.post("student/register/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    getFiles() {
        // return http.get("/files");
    }
    uploadUserProfile(formData, onUploadProgress) {

        var token = JSON.parse(localStorage.getItem("userData")).token;

        return http.post("profile/upload-user-photo/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Token ${token}`
            },
            onUploadProgress
        });
    }

    getProfilePhoto() {

        if (localStorage.getItem("userData")) {
            var token = JSON.parse(localStorage.getItem("userData")).token;

            return http.get("profile/upload-user-photo/", {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Token ${token}`
                }
            });
        }
    }
}

export default new UploadFilesService();