/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import { store } from './store'

import * as VueSpinnersCss from "vue-spinners-css";

Vue.use(VueSpinnersCss)

//import 'bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// https://bootstrap-vue.org/docs/components/modal#
// Install BootstrapVue
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// VUE / HTML Theme
// https://themeforest.net/item/dexam-vue-saas-startup-product-landing-page/25894963?irgwc=1&clickid=03dwlg2e9xyLUQUwUx0Mo3EAUkEwxo3y1VRVRI0&iradid=275988&irpid=2532435&iradtype=ONLINE_TRACKING_LINK&irmptype=mediapartner&mp_value1=&utm_campaign=af_impact_radius_2532435&utm_medium=affiliate&utm_source=impact_radius
//http://preview.themeforest.net/item/dexam-vue-saas-startup-product-landing-page/full_screen_preview/25894963?_ga=2.8483510.2055596974.1606047638-1761269846.1606047592

import moment from "moment";

Vue.filter("formatdate", function(value) {
    if (value) {
        return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
    }
});



Vue.filter('truncate', function(text, length) {
    if (text.length > length) {
        return text.substring(0, length);
    } else {
        return text;
    }
});

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
new Vue({
    router,
    store,
    beforeCreate() { this.$store.commit('initialiseStore'); },
    render: h => h(App),
    created() {
        if (localStorage.getItem('userData')) {
            this.$store.dispatch('autoSignIn', JSON.parse(localStorage.getItem('userData')))
        }
        //this.$store.dispatch('loadWalletBalance')
    },
}).$mount('#app')