<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <div
      class="
        navbar-search navbar-search-dark
        form-inline
        mr-3
        d-none d-md-flex
        ml-lg-auto
      "
    ></div>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <!-- <img alt="Image placeholder" src="img/theme/team-4-800x800.jpg" /> -->
                    <img
                      v-if="profile_photo"
                      :src="profile_photo"
                      style="border: 1px solid white;"
                    />
                    <img
                      v-else
                      src="img/theme/team-4-800x800.jpg"
                    />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold"
                >{{ first_name }} {{ last_name }}</span
              >
            </div>
          </div>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <!-- <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Settings</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-calendar-grid-58"></i>
                            <span>Activity</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-support-16"></i>
                            <span>Support</span>
                        </router-link> -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span @click="onLogout">Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import axios from "axios";
import API_URL from "./../common/config";
import UploadService from "../services/UploadFilesService";

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      username: "",
      first_name: "",
      last_name: "",
      profile_photo: '',
      cover_photo: '',
    };
  },
  created() {
    this.profileDetails();
    this.getProfilePhoto();
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    onLogout() {
      this.$store.dispatch("logout");
      //console.log('user logging', ' Top bar')
    },
    profileDetails() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "profile/user-details/", { headers: headers })
        .then((res) => {
          //console.log('profileDetails data 1', res.data)
          this.first_name = res.data[0].first_name;
          this.last_name = res.data[0].last_name;
          this.username = JSON.parse(localStorage.getItem("userData")).username;
        });
    },
    getProfilePhoto(){
      UploadService.getProfilePhoto().then((res)=>{
        this.cover_photo = "img/theme/profile-cover.jpg"
        if(res.data.length > 0){
          this.profile_photo = res.data[0].profile_photo
          this.cover_photo = res.data[0].profile_photo
        }
      })
    },
  },
};
</script>
