import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import AuthGuard from '@/auth-guard'
Vue.use(Router)

export default new Router({
    linkExactActiveClass: 'active',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'home',
            component: () =>
                import ( /* webpackChunkName: "demo" */ './views/Home.vue'),
            props(route) {
                return route.query || {}
            }
        },
        {
            path: '/about-us',
            name: 'about us',
            component: () =>
                import ( /* webpackChunkName: "demo" */ './views/AboutUs.vue')
        },
        {
            path: '/contact-us',
            name: 'contact us',
            component: () =>
                import ( /* webpackChunkName: "demo" */ './views/ContactUs.vue')
        },
        {
            path: '/student-success-page',
            name: 'student registration successfully',
            component: () =>
                import ( /* webpackChunkName: "demo" */ './views/StudentSuccessPage.vue')
        },
        {
            path: '/dhcontest-film-academy',
            name: 'DHCONTEST Film Academy',
            component: () =>
                import ( /* webpackChunkName: "demo" */ './views/FilmAcademy.vue')
        },
        {
            path: '/',
            //redirect: 'home',
            component: AuthLayout,
            children: [

                {
                    path: 'login',
                    name: 'login',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/Login.vue')
                },
                {
                    path: 'register',
                    name: 'register',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/Register.vue')
                },
                {
                    path: 'referal-signup/:code',
                    name: 'referal signup',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/ReferralRegister.vue'),
                    props: true
                },
                {
                    path: 'contestant-profile',
                    name: 'Contestant Profile',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/ContestantProfile.vue'),
                },

                // Audition Contestant Login //
                {
                    path: 'audition',
                    name: 'audition',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './audition-login/index.vue'),
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'login-contestant/:id',
                    name: 'login contestant',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './audition-login/login.vue'),
                    props: true
                },
                {
                    path: 'signup-contestant/:id',
                    name: 'signup contestant',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './audition-login/signup.vue'),
                    props: true
                },
                {
                    path: 'student-register',
                    name: 'student register',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/StudentRegister.vue')
                },

            ]
        },
        {
            path: '/',
            component: DashboardLayout,
            //name: 'dashboard',
            redirect: 'dashboard',
            beforeEnter: AuthGuard,
            children: [{
                    path: '/dashboard',
                    name: 'dashboard',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/Dashboard.vue'),
                    //alias: '/dashboard'
                },
                {
                    path: 'icons',
                    name: 'icons',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/Icons.vue'),
                    alias: 'user/icons'
                },
                {
                    path: 'profile',
                    name: 'profile',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/UserProfile.vue'),
                    alias: '/profile'
                },
                {
                    path: 'referral-bonus',
                    name: 'referral bonus',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/ReferralBonus.vue'),
                    alias: '/referral-bonus'
                },
                {
                    path: 'referral-bonus-withdrawal',
                    name: 'referral bonus withdrawal',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/ReferralBonusWithdrawal.vue'),
                },
                {
                    path: 'reality-tv-shows',
                    name: 'Reality TV shows',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/RealityTVshows.vue')
                },
                {
                    path: 'about-reality-tv-shows/:id',
                    name: 'About Reality TV shows',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/AboutRealityTVshows.vue'),
                    props: true
                },
                {
                    path: 'live-show-vote-list',
                    name: 'Live Shows Vote',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/LiveShowVoteList.vue')
                },
                {
                    path: 'live-show-vote/:id',
                    name: 'Live Show Vote',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/LiveShowVote.vue'),
                    props: true
                },
                {
                    path: 'live-show-results',
                    name: 'Live Show Results',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/ShowResults.vue')
                },
                {
                    path: 'upcoming-shows',
                    name: 'Schedule Shows',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/UpcomingShows.vue')
                },
                {
                    path: 'online-house',
                    name: 'Online House',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/OnlineHouse.vue')
                },
                {
                    path: 'live-stream',
                    name: 'Live Stream',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/LiveStream.vue')
                },
                {
                    path: 'replay-video',
                    name: 'Replay Video',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/ReplayVideo.vue')
                },

                {
                    path: 'replay-video/:id',
                    name: 'Replay Shows Videos',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/ReplayVideoLists.vue'),
                    props: true
                },
                {
                    path: 'replay-video-details',
                    name: 'Replay Shows Video',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/ReplayVideoDetails.vue'),
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'wallet',
                    name: 'Wallet',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/Wallet.vue'),
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'payment-complain/:payment_ref',
                    name: 'payment complain',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/WalletPaymentComplain.vue'),
                    props: true
                },
                {
                    path: 'coins-wallet',
                    name: 'Video Coins Wallet',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './users/VideoCoinsWallet.vue')
                },
                {
                    path: 'add-bank-details',
                    name: 'Add Bank Details',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/AddBankDetails.vue')
                },
                {
                    path: 'wallet-payment',
                    name: 'wallet payment',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './payment/WalletPayment.vue'),
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'payment-success',
                    name: 'payment success',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './payment/PaymentSuccess.vue'),
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'vote-contestant/:id',
                    name: 'vote contestant',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './audition-login/vote-contestant.vue'),
                    props: true
                },
                {
                    path: 'logout',
                    name: 'logout'
                }
            ]
        },

    ]
})