<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground">
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-world-2 text-primary',
            path: '/dashboard',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Reality TV shows',
            icon: 'ni ni-tv-2 text-blue',
            path: '/reality-tv-shows',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Live Shows Vote',
            icon: 'ni ni-check-bold text-dark-blue',
            path: '/live-show-vote-list',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Show Results',
            icon: 'ni ni-trophy text-blue',
            path: '/live-show-results',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Schedule Shows',
            icon: 'ni ni-camera-compact text-orange',
            path: '/upcoming-shows',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Online House',
            icon: 'ni ni-shop text-green',
            path: '/online-house',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Live Stream',
            icon: 'ni ni-cloud-download-95 text-blue',
            path: '/live-show-vote-list',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Replay Video',
            icon: 'ni ni-album-2 text-blue',
            path: '/replay-video',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Top-up your Wallet',
            icon: 'ni ni-credit-card text-dark-grean',
            path: '/wallet',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Coins Bonus Wallet',
            icon: 'ni ni-satisfied text-orange',
            path: '/coins-wallet',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Referral Bonus',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/referral-bonus',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Referral Bonus Withdrawal',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/referral-bonus-withdrawal',
          }"
        />
        <sidebar-item
          :link="{
            name: 'User Profile',
            icon: 'ni ni-single-02 text-yellow',
            path: '/profile',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Add Bank Details',
            icon: 'ni ni-credit-card text-blue',
            path: '/add-bank-details',
          }"
        />
        <div id="logout-div">
          <a :background-color="sidebarBackground">
            <i class="ni ni-user-run text-red" id="logout-i"></i>
            <span class="sidebar-item" @click="onLogout">Logout</span>
          </a>
        </div>
        <!-- <sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-blue', path: '/icons'}"/> -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      LoginNavGuard: false,
    };
  },
  created() {
    this.isLoginMenu();
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isLoginMenu() {
      //console.log('= checkLogin 1 =', localStorage.getItem('userData'))
      if (localStorage.getItem("userData")) {
        this.LoginNavGuard = true;
        //console.log('= LoginNavGuard 1=', this.LoginNavGuard)
        return this.LoginNavGuard;
      } else {
        //console.log('= LoginNavGuard 2=', this.LoginNavGuard)
        return this.LoginNavGuard;
      }
    },
    onLogout() {
      //console.log('user logout 1', 'Side bar')
      this.$store.dispatch("logout");
      //console.log('user logout 2', 'Side bar')
    },
  },
};
</script>
<style lang="scss">
a span {
  cursor: pointer;
}
#logout-div {
  margin-top: 10px;
}
#logout-i {
  margin-left: 23px;
  margin-right: 18px;
}
.sidebar-item {
  color: #5f5f5f !important;
  font-size: 14px !important;
}
</style>
